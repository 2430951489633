<template>
  <div class="page">
    <PageTitle :title="title" :routes="routes" :border="false"></PageTitle>
    <router-view></router-view>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import PageTitle from '@/components/PageTitle'
export default {
  name: 'Feed',
  components: {
    PageTitle
  },
  computed: {
    title() {
      let name = '评论审核'
      if (this.routes.length > 0) name = this.routes[this.routes.length - 1].name
      return name
    },
    ...mapState({
      routes: 'route'
    })
  },
  mounted() {
  },
  beforeDestroy() {
    this.$store.commit('changeRoute', null)
  }
}
</script>

<style scoped lang="scss">
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
